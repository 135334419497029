import { apenasNumeros } from '@/@core/utils/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    dadosUsuario: {},
  },
  getters: {},
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
    alterarDadosUsuario(state, payload) {
      state.dadosUsuario = payload
    },
  },
  actions: {
    fetchUsers({ commit }, queryParams) {
      commit('alteraLoading', true)

      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => {
            commit('alteraLoading', false)
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    fetchEmpresas() {
      return new Promise((resolve, reject) => {
        axios
          .get('/empresas')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles?perPage=1000')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },
    fetchPermissions() {
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions?perPage=1000')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMyProfile() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addUser({ state }, userData) {
      // eslint-disable-next-line no-param-reassign
      userData.classes = userData.classes.map(item => item.id)
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },

    update(ctx, userData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('name', userData.name)
        formData.append('setor', userData.setor || '')
        formData.append('funcao', userData.funcao)
        if (userData.telefone) {
          formData.append('telefone', apenasNumeros(userData.telefone))
        }

        userData.roles.forEach(item => {
          formData.append('roles[]', item)
        })
        userData.permissions.forEach(item => {
          formData.append('permissions[]', item)
        })
        userData.classes.forEach(item => {
          formData.append('classes[]', item.uuid || item.id)
        })

        Object.keys(userData.empresas)
          .forEach(chave => {
            formData.append(`empresas[${chave}]`, userData.empresas[chave])
          })

        if (typeof userData.imagem instanceof File) {
          formData.append('imagem', userData.imagem)
        }

        formData.append('_method', 'PUT')

        axios
          .post(`users/${userData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updatePerfil(ctx, { userData }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('telefone', apenasNumeros(userData.telefone))

        if (userData.imagem && typeof (userData.imagem) !== 'string') {
          formData.append('imagem', userData.imagem)
        }

        if (userData.password) {
          formData.append('password', userData.password)
          formData.append('password_confirmation', userData.password_confirmation)
        }

        formData.append('_method', 'PUT')

        axios
          .post('/auth/user', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },

    resetUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}/reset`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
