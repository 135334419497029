<template>
  <div>
    <b-row class="justify-content-between">
      <b-col col lg="12" class="mt-0 pt-0">
        <h4>Editar: {{ userData.name }}</h4>
      </b-col>
    </b-row>
    <b-form>
      <b-row>
        <!-- Nome Completo -->
        <b-col cols="12" md="4">
          <b-form-group label="Nome" label-for="nome">
            <b-form-input id="nome" v-model="userData.name"/>
          </b-form-group>
        </b-col>

        <!-- Nome Completo -->
        <b-col cols="12" md="4">
          <b-form-group label="Função" label-for="funcao">
            <b-form-input id="funcao" v-model="userData.funcao"/>
          </b-form-group>
        </b-col>

        <!-- Telefone -->
        <b-col cols="12" md="4">
          <b-form-group label="Telefone" label-for="telefone">
            <b-form-input
                id="telefone"
                v-model="userData.telefone"
                v-mask="'(##) # ####-####'"
                placeholder="(00) 0 0000-0000"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Usuário" label-for="username">
            <b-form-input
                id="username"
                v-model="userData.username"
                type="text"
                disabled
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group label="Perfil" label-for="roles">
            <v-select
                v-model="userData.roles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="roles"
                :multiple="true"
            >
              <template v-slot:no-options> Nenhuma opção encontrada</template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Field: Empresa -->
        <b-col cols="12" md="4">
          <b-form-group label="Empresa" label-for="empresa">
            <v-select
                v-model="userData.empresas"
                :options="listaEmpresas"
                label="nome"
                :reduce="(val) => val.uuid"
                :clearable="false"
                input-id="user-empresas"
                placeholder="Escolha uma empresa"
                multiple
            >
              <template v-slot:no-options> Nenhuma opção encontrada</template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Classes" label-for="classes">
            <select-com-pesquisa
                v-model="userData.classes"
                url="/classes"
                placeholder="Escolha a classe"
                :multiple="true"
                :disabled="false"
                name="nome"
                :itens-para-edicao="userData.classes || []"
                @selecionados="tratarEventoClassesSelecionadas"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Setor Atual" label-for="setor">
            <v-select
                v-model="userData.setor"
                :options="listaSetores"
                label="nome"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-setor"
                placeholder="Escolha um setor"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <b-card v-if="$can('listar', 'permissões')" no-body class="border mt-1">
      <b-row class="m-0 p-1">
        <b-col cols="12" md="6" align-self="center" class="p-0">
          <b-card-header class="p-0">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18"/>
              <span class="align-middle ml-50"
              >Permissões Exclusivas ({{
                  permissoesSelecionadas.length
                }})</span
              >
            </b-card-title>
          </b-card-header>
        </b-col>
        <b-col cols="12" md="6" align-self="center" class="p-0">
          <b-form-input
              v-model="filtroPermissoesSemPapel"
              placeholder="Digite pelo menos 3 caracteres para pesquisar"
          />
        </b-col>
      </b-row>

      <b-row v-if="exibirPermissoes('semPapel')" class="py-2">
        <b-col
            v-for="(perm, index) in getPermissoesSemPapel"
            :key="index"
            cols="3"
        >
          <b-form-checkbox-group>
            <b-form-checkbox
                v-model="permissoesSelecionadas"
                class="mx-1 mt-1"
                :value="perm"
            >
              {{ perm }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <b-row class="m-0 py-2 justify-content-center" v-else>
        <b-card-text class="font-medium-2 font-weight-bold"
        >
          <feather-icon
              class="text-warning"
              icon="AlertTriangleIcon"
              size="20"
          />
          Nenhuma permissão encontrada!
        </b-card-text
        >
      </b-row>
    </b-card>
    <b-card v-if="$can('listar', 'permissões')" no-body class="border mt-1">
      <b-row class="m-0 p-1">
        <b-col cols="12" md="6" align-self="center" class="p-0">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18"/>
              <span class="align-middle ml-50"
              >Permissões do Perfil ({{ permissionsDoPapel.length }})</span
              >
            </b-card-title>
          </b-card-header>
        </b-col>

        <b-col cols="12" md="6" align-self="center" class="p-0">
          <b-form-input
              v-model="filtroPermissoesDoPapel"
              placeholder="Digite pelo menos 3 caracteres para pesquisar"
          />
        </b-col>
      </b-row>

      <b-row v-if="exibirPermissoes('doPapel')" class="py-1">
        <b-col
            v-for="(perm, index) in getPermissoesDoPapel"
            :key="index"
            cols="3"
        >
          <b-form-checkbox-group>
            <b-form-checkbox
                v-model="permissionsDoPapel"
                disabled
                class="mx-1 mt-1"
                :value="perm"
            >
              {{ perm }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <b-row class="m-0 py-2 justify-content-center" v-else>
        <b-card-text class="font-medium-2 font-weight-bold"
        >
          <feather-icon
              class="text-warning"
              icon="AlertTriangleIcon"
              size="20"
          />
          Nenhuma permissão encontrada!
        </b-card-text
        >
      </b-row>
    </b-card>
    <!-- Action Buttons -->
    <b-col class="text-right pr-0">
      <b-button
          variant="outline-secondary"
          type="button"
          :to="{ name: 'usuários' }"
      >
        Voltar
      </b-button>
      <b-button
          variant="success"
          type="button"
          class="mb-1 mb-sm-0 mr-0 ml-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="editarUsuario(userData)"
      >
        Salvar
      </b-button>
    </b-col>
  </div>
</template>

<script>
import { avatarText, filtrarItemLista } from '@core/utils/filter'
import {
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { computed, onMounted, ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BFormCheckboxGroup,
    SelectComPesquisa,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    listaEmpresas: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const refInputEl = ref(null)
    const previewEl = ref(null)
    const roleOptions = ref([])
    const listaClasses = ref(props.userData.classes)
    const listaSetores = computed(() => store.state.app.listaSetores)

    // Use toast
    const toast = useToast()

    const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        (base64) => {
          // eslint-disable-next-line no-param-reassign
          // props.userData.avatar = base64
        },
    )
    const permissionsData = ref([])
    const permissionsSemPapel = ref([])
    const permissionsDoPapel = ref([])
    const permissoesSelecionadas = ref([])

    const fetchRoleOptions = async () => {
      const response = await store.dispatch('app-user/fetchRoles')
      roleOptions.value = response.data
          .filter((role) => role.name !== 'ADM' && role.name !== 'SAM')
          .map((role) => ({
            value: role.name,
            label: role.name,
          }))
    }

    const fetchPermissions = async () => {
      const response = await store.dispatch('app-user/fetchPermissions')

      permissionsData.value = response.data.map((perm) => perm.name)
      permissionsSemPapel.value = permissionsData.value
    }

    const fetchUserDataAndPermissions = async () => {
      await store
          .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
          .then((resp) => {
            permissionsSemPapel.value = permissionsSemPapel.value.filter(
                (perm) =>
                    !resp.data.role_permissions.some(
                        (selectedPerm) => selectedPerm === perm,
                    ),
            )
            permissoesSelecionadas.value = resp.data.permissions
            permissionsDoPapel.value = resp.data.role_permissions
          })
    }

    onMounted(async () => {
      await fetchPermissions()
      await fetchUserDataAndPermissions()
      await fetchRoleOptions()
    })

    const editarUsuario = (item) => {
      const dadosUsuario = {
        ...item,
        permissions: permissoesSelecionadas.value,
      }
      store.dispatch('app-user/update', dadosUsuario)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário editado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
    }

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      permissionsData,
      permissoesSelecionadas,
      editarUsuario,
      refInputEl,
      previewEl,
      inputImageRenderer,
      listaClasses,
      listaSetores,
      permissionsSemPapel,
      permissionsDoPapel,
    }
  },

  data() {
    return {
      filtroPermissoesSemPapel: '',
      permissionsSemPapelFiltrado: [],
      filtroPermissoesDoPapel: '',
      permissionsDoPapelFiltrado: [],
      quantidadeMinimaCaracteresFiltro: 3,
    }
  },

  computed: {
    getPermissoesSemPapel() {
      return this.permissionsSemPapelFiltrado.length > 0
          ? this.permissionsSemPapelFiltrado
          : this.permissionsSemPapel
    },
    getPermissoesDoPapel() {
      return this.permissionsDoPapelFiltrado.length > 0
          ? this.permissionsDoPapelFiltrado
          : this.permissionsDoPapel
    },
  },

  watch: {
    filtroPermissoesSemPapel() {
      if (
          this.filtroPermissoesSemPapel.length >=
          this.quantidadeMinimaCaracteresFiltro
      ) {
        this.filtrarPermissoes('semPapel')
      }

      if (this.filtroPermissoesSemPapel.length === 0) {
        this.atualizarPermissoesSemPapel()
      }
    },

    filtroPermissoesDoPapel() {
      if (
          this.filtroPermissoesDoPapel.length >=
          this.quantidadeMinimaCaracteresFiltro
      ) {
        this.filtrarPermissoes('doPapel')
      }

      if (this.filtroPermissoesDoPapel.length === 0) {
        this.permissionsDoPapelFiltrado = []
      }
    },
  },

  methods: {
    atualizarPermissoesSemPapel() {
      this.permissionsSemPapelFiltrado = []
      this.$nextTick(() => {
        const getNovasPermissoes = this.permissionsSemPapelFiltrado
            .map((perm) => perm)
            .filter((permName) => !this.permissoesSelecionadas.includes(permName))

        this.permissoesSelecionadas = [
          ...this.permissoesSelecionadas,
          ...getNovasPermissoes,
        ]
      })
    },
    exibirPermissoes(type) {
      const permissoesFiltradas = {
        semPapel: this.permissionsSemPapelFiltrado,
        doPapel: this.permissionsDoPapelFiltrado,
      }

      const permissoes = {
        semPapel: this.permissionsSemPapel,
        doPapel: this.permissionsDoPapel,
      }

      const filtro = {
        semPapel: this.filtroPermissoesSemPapel,
        doPapel: this.filtroPermissoesDoPapel,
      }

      return (
          (permissoesFiltradas[type].length > 0 &&
              filtro[type].length >= this.quantidadeMinimaCaracteresFiltro) ||
          (permissoes[type].length > 0 &&
              filtro[type].length < this.quantidadeMinimaCaracteresFiltro)
      )
    },
    filtrarPermissoes(type) {
      const permissoesFiltradas = {
        semPapel: 'permissionsSemPapelFiltrado',
        doPapel: 'permissionsDoPapelFiltrado',
      }

      const permissoes = {
        semPapel: this.permissionsSemPapel,
        doPapel: this.permissionsDoPapel,
      }

      const filtro = {
        semPapel: this.filtroPermissoesSemPapel,
        doPapel: this.filtroPermissoesDoPapel,
      }

      this[permissoesFiltradas[type]] = filtrarItemLista(
          permissoes[type],
          filtro[type],
      )
    },
    tratarEventoClassesSelecionadas(classes) {
      this.userData.classes = classes || []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
